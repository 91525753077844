import PrimaryButton from "components/common/button";
import React from "react";
import Image from "next/image";

const custom = `
<style>

  .container strong {
    background:  linear-gradient(180deg, #AF862C 8.59%, #F8F29C 45.7%, #D9BD5C 66.66%, #AD842A 95.83%);
    font-size: 16px;
    background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  }
  line-height:28px;

</style>
`;

export const CommingSoon = ({ secure }: any) => {
  return (
    <section className="pt-30 lg:pt-30 pb-30 ">
      <div
        className="container flex py-10 2xl:py-20  justify-between gap-[1em] h-full px-10 2xl:px-20 flex-col"
        style={{
          backgroundImage: "url(/images/mask-bg.png)",
          backgroundSize:'cover'
        }}
      >
        {" "}
        <p className="text-40px font-bold text-gradient m-2">{secure?.title}</p>
        <div
          dangerouslySetInnerHTML={{
            __html: `${custom}${secure?.description}`,
          }}
          className="text-gray-300 text-16px leading-[28px] text-justify"
        />
        <a
          className="w-fit cursor-pointer relative z-10"
          href={"https://www.earthwise.global/our-people/"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="text-gradient flex items-center gap-2 text-16px">
            Discover the Teams behind the Game
            <Image
              src="/icons/next-pagination.svg"
              width={20}
              height={20}
              alt="next-pagination"
            />
          </div>{" "}
          <div
            style={{
              background: "linear-gradient(45deg, #555048 0%,#AF8342 100%)",
              height: "2px",
              width: "98%",
              marginTop: "2px",
            }}
          />
        </a>
        <div className="mt-3">
          <a
            href="https://www.earthwise.global/game-campaign"
            target="_blank"
            rel="noopener noreferrer"
          >
            <PrimaryButton text="Join the EARTHwise Movement" />
          </a>
        </div>
      </div>
    </section>
  );
};
